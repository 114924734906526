import React,{useState} from 'react';
import { IonCard, IonCardTitle, IonIcon, IonAlert } from '@ionic/react';

import { CountyData } from "../../interfaces/CountyData";

import { useHistory } from 'react-router-dom';
import {  HorizontalBar } from 'react-chartjs-2';
import DoughnutGraph  from "../Graphs/DoughnutGraph";

import "./AllCardStyles.css"

interface GraphDataCardprops {
  content: any;
  crashList: CountyData[];
  newdata:any;
}


const GraphDataCard: React.FC<GraphDataCardprops> =(props:GraphDataCardprops)=> {

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    if (props.content.source != "N/A") setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const history = useHistory();

  const handleOkay = () => {
    closeModal();  // Close the modal first
    history.push('AppCenter/TrafficCounts');  // Navigate to the desired path
  };

  const clearanceValues = props.newdata.roadAadt.map((day:any )=> Math.floor(day.lane_Volume));
  const dayNames = props.newdata.roadAadt.map((day:any) => day.name);   


  const new_data={
    labels:dayNames,
    datasets:[{label:'Freeway Counts',data:clearanceValues,backgroundColor:'tomato'}]
  }

  const new_options={
    indexAxis: 'y' as const,
    scales: {
      xAxes: [{gridLines: {drawOnChartArea: false,color: 'black'},ticks: {fontColor: "black"}}],
      yAxes: [{gridLines: {drawOnChartArea: false,color: 'black'},ticks: {fontColor: "black"}}]
           }
  }
  



return(
  <>
    <IonCard className='main-container'>
        <IonCardTitle className="my-card-header">
            <div onClick={openModal} className="icon freeway-icon">
                <IonIcon  color="light" ios={props.content.ios} md={props.content.md}/>
            </div>
            <div className='my-card-title'>Freeway ADT</div>
        </IonCardTitle>
        
        <div className="gauge-container">
          <DoughnutGraph current={props.newdata.Active.value} card={'freeway'} ></DoughnutGraph>
        </div>
          
        <IonCardTitle className='center-title' style={{ color: 'black' }}>
                  Data Rate
        </IonCardTitle>
        
       <div className='counties-list'>
            <HorizontalBar data={new_data} options={new_options} height={210}></HorizontalBar>
       </div>
        <div className='last-updated'>{props.content.updated}</div>
    </IonCard>

    <IonAlert
          isOpen={modalOpen}
          header={props.content.title}
          subHeader={"Source: " + props.content.source}
          message={props.content.description}
          buttons={[{text:"More Inforamtion", handler: handleOkay}]}
          onDidDismiss={closeModal}
          cssClass="bigger-alert"
        ></IonAlert>
    

  </>
   
    );
};
export default GraphDataCard;


