import React, { useEffect, useState, useRef } from 'react'
import { personCircleOutline, personCircleSharp, hardwareChipOutline, hardwareChipSharp, sendOutline, sendSharp,
  addCircleOutline,addCircleSharp, trashOutline, trashSharp
 } from 'ionicons/icons'
 
import { IonIcon } from '@ionic/react'
import { ThreeDot } from 'react-loading-indicators';

import firebaseService from '../../services/firebaseService'
import { getUser } from '../../firebaseConfig'

import { ChatMessage } from '../../interfaces/ChatMessage'
import Header from '../../components/Header/Header'
import {User} from '../../interfaces/User';

import "./TitanBot.css"


const TitanBot: React.FC = () => {

  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
  const [allchatMessages, setallChatMessages] = useState<{ chatid: string,apiChatid:string,  chatMessages: ChatMessage[] }[]>([]);
  const [currentChatId, setCurrentChatId] = useState<string>("activeSession");
  const [apiChatId,SetApiChatId]=useState('');
  const [loading, setloading] = useState<boolean>(false);
  const [userinput, setuserinput] = useState('');
  const [initialRender, setinitialRender] = useState<boolean>(false);
  const [user,SetUser] = useState(new User());



  useEffect(() => {

    const LoadInitialChat = async () => {
      const user = await getUser();
      SetUser(user?.data() as User);
      const getChat = await firebaseService.getUserChat(user?.data()?.uid);

      if (getChat.length === 0) {
        await firebaseService.addDummyFieldToUserDoc(user?.data()?.uid);
        setChatMessages([{ 'type': 'bot', 'message': 'Hi, I am TitanBot. How can I help you ?' }]);
        const apichatid=await firebaseService.getApiChatId(user?.data()?.uid,"activeSession");
        SetApiChatId(apichatid);

      }
      else {
        setallChatMessages(getChat);
        const curr_chat = getChat.filter((chat) => chat.chatid === 'activeSession');
        if (curr_chat.length > 0) {
          setChatMessages(curr_chat[0].chatMessages);
          SetApiChatId(curr_chat[0].apiChatid);
        }
      }
      setinitialRender(true);
    }

    LoadInitialChat();
  }, [])



  useEffect(() => {

    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }


    if (initialRender) {
      const PushDataToFirestore = async () => {
        const user = await getUser();
        await firebaseService.PushChatToFirestore(chatMessages, user?.data()?.uid, currentChatId);
        const getChat = await firebaseService.getUserChat(user?.data()?.uid);
        setallChatMessages(getChat);
      }
      PushDataToFirestore();
    }

  }, [chatMessages, initialRender]);


  const messagesEndRef = useRef<HTMLDivElement>(null);



  function linkify(text: string) {
    // Step 1: Linkify URLs
    const formattedText = text.replace(
      // eslint-disable-next-line no-useless-escape
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig,
      (url) => `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
    );

    // Step 2: Format numbered points (e.g., 1., 2., 3.) to start on new lines
    // formattedText = formattedText.replace(
    //   /(\d+\.\s)/g,
    //   '<br /><strong>$1</strong>' // Add a line break and bold the numbered point
    // );
    return formattedText;
  }

  const newChatClicked = async () => {

    const user = await getUser();
    if (user !== null) {
      setCurrentChatId("activeSession");
      const apichatid=await firebaseService.CreateNewChat(user?.data()?.uid);
      const getChat = await firebaseService.getUserChat(user?.data()?.uid);
      setallChatMessages(getChat);
      setChatMessages([{ 'type': 'bot', 'message': 'Hi, I am TitanBot. How can I help you ?' }]);
      // const apichatid=await firebaseService.getApiChatId(user?.data()?.uid,"activeSession");
      SetApiChatId(apichatid);
    }
  }
  
  const handleSubmit = (e:any)=>{
    e.preventDefault();
    SubmitMessage();
  }


  const SubmitMessage = async () => {
    if (userinput !== '') {

      const userMessage = userinput;
      const user_id=user.uid;
      const api_id=user_id+"+"+apiChatId;
      // console.log('user_id:',user_id);
      // console.log('chat_id:',api_id);
      setuserinput('');
      setChatMessages(prevMessages => [...prevMessages, { 'type': 'user', 'message': userMessage }]);
      setloading(true);
      try{
        // for production
        const titanbot_api = await fetch(`https://chattest.titanlab.us/chat/${user_id}/${api_id}/${userMessage}`);
        // make some api end point for local developement to avoid cors error
        // const titanbot_api = await fetch(`http://localhost:5000/api/chat?user_input=${userMessage}&user_id=${user_id}&conversation_id=${api_id}`);
        const data = await titanbot_api.json();
        const bot_response = linkify(data.response);
        const graph_image = data?.graph_name;
        // console.log('graph_image:',graph_image);
        setloading(false);

        // Construct the bot message with optional image
        const botMessage:ChatMessage = { type: 'bot', message: bot_response };
        if (graph_image) {
          botMessage.image = graph_image;  // Add image if available in the response
        }
        setChatMessages(prevMessages => [...prevMessages, botMessage]);
      }
      catch(error)
      {
        console.log('error calling api:',error);
        setChatMessages(prevMessages => [...prevMessages, { 'type': 'bot', 'message': 'Something Went Wrong with TitanBot. Please try again after some time.' }]);
        setloading(false);
      }
    }
  }

  const handleClick = async (chat: any) => {
    setCurrentChatId(chat.chatid);
    const apichatid=await firebaseService.getApiChatId(user.uid,chat.chatid);
    SetApiChatId(apichatid);
    setChatMessages(chat.chatMessages);
  }

  const handleInputChange = (e: any) => {
    setuserinput(e.target.value);
  }

  const formatChatId=(chatid:string)=>{
    if(chatid.split("-").length===1)
      {
        return "Current Session";
      }
    const [year,month,day,hour,minute,seconds]=chatid.split("-").map(Number);
    const date = new Date(year,month-1,day,hour,minute);

    const formattedDate= date.toLocaleDateString("en-us",{
      month:"short",
      day:"numeric",
      year:"numeric",
    });

    const formattedTime= date.toLocaleTimeString("en-us",{
      hour:"2-digit",
      minute:"2-digit",
      hour12:true,
    });

    return `${formattedDate},${formattedTime}`;

  }

  const deleteChat=async (chatid:string)=>{

    const user = await getUser();

    if (user !== null) {
      await firebaseService.DeleteChatId(user?.data()?.uid,chatid);
      const getChat = await firebaseService.getUserChat(user?.data()?.uid);
      setallChatMessages(getChat);
      
      allchatMessages.forEach((chat,index)=>{
        if(chat.chatid==='activeSession')
          {
            setCurrentChatId('activeSession');
            setChatMessages(chat.chatMessages);
            SetApiChatId(chat.apiChatid);
          }

      });
    }

    
    
  }

  return (

    <div className='titanbot-page'>
      <Header title='TitanBot AI' />
      <div className="chatbox">
        <div className="chatbox-sidebar">
          <div className='chat-history-list'>
            <button onClick={newChatClicked} className={`history-list-button new-chat-button`}>
              New Chat 
              <IonIcon slot='start' ios={addCircleOutline} md={addCircleSharp} className='new-chat-icon'/>
            </button>
            {allchatMessages.slice().reverse().map((chat, index) => (
              <div key={index} onClick={(e) => handleClick(chat)} 
              className={`history-list-button ${currentChatId === chat.chatid ? 'current-session' : ''}`}>
                <span>{formatChatId(chat.chatid)} </span>
                {chat.chatid !== 'activeSession' &&
                <button className='delete-chat-button' onClick={(e)=>{e.stopPropagation(); deleteChat(chat.chatid);}}>
                  <IonIcon  ios={trashSharp} md={trashSharp} />
                </button>
                  }
              </div>
            ))}
          </div>
        </div>
        <div className="chatbox-messages">
          <div className='messages-list-scroll'>
            <div className="messages-list">
              {
                chatMessages.map((message, index: any) => (
                  <div key={index} className={`chat-message ${message.type === 'bot' ? 'bot-message' : ''}`}>
                    <div className='avatar-message'>
                      <div className="avatar">
                        {message.type === 'user' ? <IonIcon slot="start" ios={personCircleOutline} md={personCircleSharp} className='profile-icons' /> :
                          <IonIcon slot="start" ios={hardwareChipOutline} md={hardwareChipSharp} className='profile-icons' />}
                      </div>
                      <div className="message" key={index} dangerouslySetInnerHTML={{ __html: message.message }} />
                    </div>
                    {
                      message.image && 
                        <div className='graph-image'>
                            <img src={`https://chattest.titanlab.us/graph/${message.image}`} alt="Graph" className='graph-image' />
                       </div>
                    }                    
                  </div>
                ))
              }
              <div ref={messagesEndRef} />
            </div>
            
          </div>
          <div className="user-input">
            <form onSubmit={handleSubmit}>
              <div className='input-container'>
                <input className='chat-input-textarea' placeholder='Type your message here' onChange={handleInputChange} disabled={loading} value={userinput}/>
                {
                  loading ? <div className='loading-dots'> <ThreeDot color="black" size="small" text="" textColor="" /> </div> :
                    <IonIcon className={` send-icon ${userinput === '' ? '' : 'send-icon-black'}`} slot="start" ios={sendOutline} md={sendSharp} onClick={SubmitMessage} />
                }
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  )
}

export default TitanBot