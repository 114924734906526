import React,{useState} from 'react';
import { IonCard, IonCardTitle, IonIcon,IonAlert } from '@ionic/react';
import { CountyData } from "../../interfaces/CountyData";
import { useHistory } from 'react-router-dom';


import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CircleIcon from '@mui/icons-material/Circle';


import {Line} from 'react-chartjs-2';

import "./AllCardStyles.css"

interface GraphDataCardprops {
    content: any;
    crashList: CountyData[];
    newdata:any;
  }

interface dayClearance{
  clearance: number;
  name: string;
}
  
const ClearanceDataCard: React.FC<GraphDataCardprops> =(props:GraphDataCardprops)=> {


    const [modalOpen, setModalOpen] = useState(false);
    const openModal = () => {
      if (props.content.source != "N/A") setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
    };
    const history = useHistory();
    const handleOkay = () => {
      closeModal();  // Close the modal first
      history.push('/AppCenter/ClearanceTime');  // Navigate to the desired path
    };

    const clearanceValues = props.newdata.weekdayClearance.map((day:any )=> Math.floor(day.clearance));
    const dayNames = props.newdata.weekdayClearance.map((day:any) => day.name.substring(0, 3));   
    
    const line_data={
        labels:dayNames,
        datasets:[{label:'Clearance time by Day ', data:clearanceValues, backgroundColor:'magenta', borderColor:'blue', pointBorderColor:'magenta', fill:false}]
    }
    
    const line_options = {
      scales: {
                 xAxes: [{gridLines: {drawOnChartArea: false,color: 'black'},ticks: {fontColor: "black"}}],
                 yAxes: [{gridLines: {drawOnChartArea: false,color: 'black'},ticks: {fontColor: "black"}}]
              }
    }


  return(
    <>
      <IonCard className='main-container'>
          <IonCardTitle className="my-card-header">
              <div onClick={openModal} className="icon clearance-icon">
                  <IonIcon  color="light" ios={props.content.ios} md={props.content.md}/>
              </div>
              <div className='my-card-title'>{props.content.title}</div>
                
          </IonCardTitle>
          
          <div className="clearance-line-graph">
                  <Line data={line_data} options={line_options} ></Line>
          </div>

          <IonCardTitle className='center-title' style={{ color: 'black' }}>
                  Clearance Time By County
          </IonCardTitle>
        
         
          <div className='counties-list'>
              {
                props.newdata.countyClearance.map((day:any,index:number)=>{
                  return(
                    <div key={index} className='county-and-arrow'>
                        <CircleIcon className='circle-dot' style={{fill:'DodgerBlue',fontSize: 12}}></CircleIcon>
                        <div key={day.name}>{day.name} : {Math.floor(day.clearance)}</div>
                        {day.clearance>props.newdata.clearanceTime.value?
                        <ArrowUpwardIcon className='arrow' style={{ color:'red',fontSize: 20 }}></ArrowUpwardIcon>
                        :<ArrowDownwardIcon className='arrow' style={{ color:'green', fontSize:20 }}></ArrowDownwardIcon>
                        }
                    </div>
                  )
                })
              }
          </div>
          <div className='last-updated'>{props.content.updated}</div>
      </IonCard>

      <IonAlert
          isOpen={modalOpen}
          header={props.content.title}
          subHeader={"Source: " + props.content.source}
          message={props.content.description}
          buttons={[{text:"More Information", handler: handleOkay}]}
          onDidDismiss={closeModal}
          cssClass="bigger-alert"
        ></IonAlert>  
    </>
     
      );
  };
  export default ClearanceDataCard;
  