import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/auth';
import 'firebase/storage';


import { User } from '../interfaces/User';
import { ChatMessage } from '../interfaces/ChatMessage';
import { getFireUser } from '../firebaseConfig';
import FileDoc from '../interfaces/FileDoc';
import { UserFeedback } from '../interfaces/UserFeedback';



const firebaseService = {
    getAuthToken: (): Promise<firebase.firestore.DocumentSnapshot> => {
        return firebase.firestore().collection('API-TOKEN').doc('1').get();
    },

    createLink: (page: string): Promise<firebase.firestore.DocumentSnapshot> => {
        return firebase.firestore().collection('Links').doc(page).get();
    },

    getLinks: (): Promise<firebase.firestore.QuerySnapshot> => {
        return firebase.firestore().collection('Links').get();
    },

    getFiles: (): firebase.firestore.CollectionReference => {
        return firebase.firestore().collection('Files').doc(getFireUser()?.uid)
            .collection('Files');
    },

    deleteFile: (fileID: string): Promise<void> => {
        return firebase.firestore().collection('Files').doc(getFireUser()?.uid)
            .collection('Files').doc(fileID).delete();
    },

    uploadFileDoc: (file: FileDoc): Promise<firebase.firestore.DocumentReference> => {
        delete file.data;
        const tags: string[] = [];
        file.tags.forEach(tag => {
            tags.push(tag.value);
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const fileToUpload: any = Object.assign({}, file);
        fileToUpload.tags = tags;
        return firebase.firestore().collection('Files').doc(getFireUser()?.uid)
            .collection('UploadedFiles').add(fileToUpload);
    },

    uploadFile: (file: File): firebase.storage.UploadTask => {
        const storage = firebase.storage().ref();
        const fileRef = storage.child('userFiles/' + getFireUser()?.uid + '/' + file.name);
        return fileRef.put(file);
    },

    getUploadedFiles: (): Promise<firebase.storage.ListResult> => {
        const storage = firebase.storage().ref();
        const fileRef = storage.child('userFiles/' + getFireUser()?.uid);
        return fileRef.listAll();
    },

    getUploadedReferences: (): firebase.firestore.CollectionReference => {
        return firebase.firestore().collection('Files').doc(getFireUser()?.uid)
            .collection('UploadedFiles');
    },

    subscribeTokenToTopic(data: { tokens: string[], topic: string }): Promise<firebase.functions.HttpsCallableResult> {
        const subscribe = firebase.functions().httpsCallable('subscribeUser');
        return subscribe(data);
    },

    unsubscribeTokenFromTopic(data: { tokens: string[], topic: string }): Promise<firebase.functions.HttpsCallableResult> {
        const unsubscribe = firebase.functions().httpsCallable('unsubscribeUser');
        return unsubscribe(data);
    },

    getAllNotificationsCollection: (): firebase.firestore.CollectionReference => {
        return firebase.firestore().collection('Users').doc(getFireUser()?.uid)
            .collection('Notifications');
    },

    getNotifications: (): firebase.firestore.Query => {
        return firebase.firestore().collection('Users').doc(getFireUser()?.uid)
            .collection('Notifications').orderBy("date", "desc");
    },

    getNotificationData: (): firebase.firestore.Query => {



        return firebase.firestore().collection('NOTIFICATIONS').doc('WAZE').collection('INCIDENTS');
    },

    getCams: (): firebase.firestore.CollectionReference => {
        return firebase.firestore().collection('CCTV_UPDATE');
    },

    watchDashboardData: (): firebase.firestore.Query => {
        return firebase.firestore().collection('Dashboard');
    },

    watchWazeIncidentsData: (): firebase.firestore.Query => {
        return firebase.firestore().collection('WAZE').doc('ACTIVE').collection('INCIDENTS');
    },

    watchWazeJamsData: (): firebase.firestore.Query => {
        return firebase.firestore().collection('WAZE').doc('ACTIVE').collection('JAMS');
    },

    watchTranscoreData: (): firebase.firestore.Query => {
        return firebase.firestore().collection('TRANSCORE').doc('ACTIVE').collection('INCIDENTS');
    },

    watchWeatherData: (): firebase.firestore.Query => {
        return firebase.firestore().collection('WEATHER').doc('RT').collection('INCIDENTS');
    },

    watchUser(uid: string): firebase.firestore.DocumentReference {
        return firebase.firestore().collection('Users').doc(uid);
    },

    getUserByEmail(email: string): Promise<firebase.firestore.QuerySnapshot> {
        return firebase.firestore().collection('Users').where("email", "==", email).get();
    },

    getUserByID(uid: string): Promise<firebase.firestore.DocumentSnapshot> {
        return firebase.firestore().collection('Users').doc(uid).get();
    },

    getUsers(): firebase.firestore.CollectionReference {
        return firebase.firestore().collection('Users');
    },

    getAppliedUsers(): firebase.firestore.Query {
        return firebase.firestore().collection('Users').where("applied", "==", true);
    },

    getUnverifiedUsers(): firebase.firestore.Query {
        return firebase.firestore().collection('Users').where('verified', '==', false);
    },

    updateUser(user: User): Promise<void> {
        return firebase.firestore().collection('Users').doc(user.uid).set(Object.assign({}, user));
    },

    updateEmail(email: string): Promise<void> | undefined {
        return firebase.auth().currentUser?.updateEmail(email);
    },

    checkStorageStatus(link: string): firebase.storage.Reference {
        return firebase.storage().refFromURL(link);
    },


    getCurrentUser(): User | null {

        const currentUser = firebase.auth().currentUser;
        if (currentUser == null)
            return null;
        return {
            uid: currentUser.uid,
            email: currentUser.email,
        } as User;
    },


    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    submitFeedbackRequest(data: UserFeedback): Promise<any> {
        data.isEmailSent = false;
        return firebase.firestore().collection('UserFeedbacks').add(data);

    },

    uploadAttachment(uid: string, data: File, uploadDate: string): firebase.storage.UploadTask {
        return firebase.storage().ref(uid + '/support_attachments/' + data.name + ' - ' + uploadDate).put(data);
    },

    getAttachementUrl(ref: firebase.storage.Reference): Promise<string> {
        return ref.getDownloadURL();
    },


    async addDummyFieldToUserDoc(uid: string): Promise<void> {
        await firebase.firestore().collection('UsersTitanBotChat')
            .doc(uid)
            .set(
                {
                    dummyField: "dummyValue"  // Add the dummy field at the 'uid' document level
                },
                { merge: true }  // Merge to avoid overwriting existing fields
            );
        await firebase.firestore().collection('UsersTitanBotChat').doc(uid).collection('session').doc('activeSession').set({ ApiChatId: new Date().toISOString() }, { merge: true });
    },



    PushChatToFirestore(chat: ChatMessage[], uid: string, chatid: string): Promise<any> {
        return firebase.firestore().collection('UsersTitanBotChat').doc(uid).collection('session').doc(chatid).set({ Messages: chat }, { merge: true });
    },

    async getCurrentChat(uid: string): Promise<ChatMessage[]> {
        const sessionDoc = await firebase.firestore().collection('UsersTitanBotChat').doc(uid).collection('session').doc('activeSession').get();
        return sessionDoc.exists ? sessionDoc.data()?.CurrentChat || [] : [];
    },

    async getUserChat(uid: string): Promise<{ chatid: string,  apiChatid:string,chatMessages: ChatMessage[] }[]> {
        const userDoc = await firebase.firestore().collection('UsersTitanBotChat').doc(uid).get();
        if (userDoc.exists) {
            const sessionCollection = await firebase.firestore().collection('UsersTitanBotChat').doc(uid).collection('session').get();
            const allChatsWithDocId: { chatid: string, apiChatid:string, chatMessages: ChatMessage[] }[] = [];
            sessionCollection.forEach(doc => {
                const chatData = doc.data()?.Messages;
                const apiChatid= doc.data()?.ApiChatId;
                if (chatData) {
                    allChatsWithDocId.push({
                        apiChatid:apiChatid,
                        chatid: doc.id,
                        chatMessages: chatData,
                    })
                }
            })
            return allChatsWithDocId;
        }
        else {
            return [];
        }
    },

    async CreateNewChat(uid: string): Promise<any> {
        const sessionDoc = await firebase.firestore().collection('UsersTitanBotChat').doc(uid).collection('session').doc('activeSession').get();
        const activeSessiondata = sessionDoc.data()?.Messages;

        const prev_apiChatId= sessionDoc.data()?.ApiChatId;
        const new_apiChatId =new Date().toISOString();
        await firebase.firestore().collection('UsersTitanBotChat').doc(uid).collection('session').doc('activeSession').set({ApiChatId:new_apiChatId}, { merge: true });

        const todayDate = new Date();
        const date = todayDate.getDate();
        const month = todayDate.getMonth() + 1; // Months are zero-based
        const year = todayDate.getFullYear();
        const hours = todayDate.getHours();
        const minutes = todayDate.getMinutes();
        const Seconds=todayDate.getSeconds();

        // Format the string as 'YYYY-MM-DD-HH-MM'
        const timeid = `${year}-${month < 10 ? '0' + month : month}-${date < 10 ? '0' + date : date}-${hours < 10 ? '0' + hours : hours}-${minutes < 10 ? '0' + minutes : minutes}-${Seconds<10?'0'+Seconds:Seconds}`;
         
        await firebase.firestore().collection('UsersTitanBotChat').doc(uid).collection('session').doc(timeid).set({ Messages: activeSessiondata, ApiChatId:prev_apiChatId }, { merge: true });
        return new_apiChatId;
    },

    async getApiChatId(uid:string,chatid:string):Promise<any>{
        const sessionDoc = await firebase.firestore().collection('UsersTitanBotChat').doc(uid).collection('session').doc(chatid).get();
        const apiChatId= sessionDoc.data()?.ApiChatId;
        return apiChatId;
    },

    async DeleteChatId(uid: string, chatid: string): Promise<any> {
        // console.log('delete chat in:', chatid);
        await firebase.firestore().collection('UsersTitanBotChat').doc(uid).collection('session').doc(chatid).delete();
        
    },

};






export default firebaseService;
