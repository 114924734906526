import React, { useState } from 'react';
import { IonCard, IonCardTitle, IonAlert } from '@ionic/react';
import { Icon } from '@iconify/react';
import { useHistory } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import "./AllCardStyles.css"
import 'bootstrap/dist/css/bootstrap.min.css';

import { Bar, Line, HorizontalBar } from 'react-chartjs-2';
interface GraphDataCardprops {
  content: any;
  crashList: any;
  newdata: any;
}

const WorkZoneDataCard: React.FC<GraphDataCardprops> = (props: GraphDataCardprops) => {
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    if (props.content.source != "N/A") setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const history = useHistory();

  const handleOkay = () => {
    closeModal();  // Close the modal first
    history.push('/AppCenter/WorkZones');  // Navigate to the desired path
  };

  const clearanceValues = props.newdata.dowWzCongestion.map((day: any) => Math.floor(day.delay));
  const dayNames = props.newdata.dowWzCongestion.map((day: any) => day.name.substring(0, 3));
  const roadNames = props.newdata.roadWzCongestion.map((day: any) => day.name);
  const roadValues = props.newdata.roadWzCongestion.map((day: any) => Math.floor(day.delay));





  const line_data = {
    labels: dayNames,
    datasets: [{
      label: 'Congestion by Day ',
      data: clearanceValues,
      backgroundColor: 'magenta',
      borderColor: 'green',
      pointBorderColor: 'magenta',
      fill: false,
    }]
  }

  const line_options = {
    scales: {
      xAxes: [{ gridLines: { drawOnChartArea: false, color: 'black' }, ticks: { fontColor: "black" } }],
      yAxes: [{ gridLines: { drawOnChartArea: false, color: 'black' }, ticks: { fontColor: "black" } }]
    }
  }

  const bar_data = {
    labels: roadNames,
    datasets: [{ label: 'Congestion By Road', data: roadValues, backgroundColor: 'tomato' }]
  }

  const bar_options = {
    indexAxis: 'y' as const,
    scales: {
      xAxes: [{ gridLines: { drawOnChartArea: false, color: 'black' }, ticks: { fontColor: "black" } }],
      yAxes: [{ gridLines: { drawOnChartArea: false, color: 'black' }, ticks: { fontColor: "black" } }]
    }
  }


  return (
    <>
      <IonCard className='main-container'>

        <IonCardTitle className="my-card-header">
          <div onClick={openModal} className="icon workzone-icon">
            <Icon color="white" icon="mdi:traffic-cone" />
          </div>
          <div className='my-card-title'>WorkZones</div>
        </IonCardTitle>

        <div className='workzone-graphs'>
          <div className='workzone-line-graph'> 
            <Line data={line_data} options={line_options} height={250} width={400}></Line>
          </div>

          <div className='work-zone-info'>
                        <Table striped bordered hover>
                              <tbody>
                                  <tr>
                                    <td>Number of WorkZones</td>
                                    <td>{props.newdata.Workzones.value}</td>
                                  </tr>
                                  <tr>
                                    <td>Avg. Queue Length</td>
                                    <td>{props.newdata['Queue Lengths'].value}</td>
                                  </tr>
                                  <tr>
                                    <td>Congested Hours</td>
                                    <td>{props.newdata['Congested Hours'].value}</td>
                                  </tr>                                
                              </tbody>
                          </Table>
            </div> 

          <div className='workzone-bar-chart'>
                <HorizontalBar data={bar_data} options={bar_options} height={250} width={400}></HorizontalBar>
          </div>


        </div>

        <div className='last-updated'>{props.content.updated}</div>



      </IonCard>
      <IonAlert
        isOpen={modalOpen}
        header="Work Zones"
        subHeader={"Information related to work Zones"}
        message={"For More Information on work zones click below"}
        buttons={[{ text: "More Information", handler: handleOkay }]}
        onDidDismiss={closeModal}
        cssClass="bigger-alert"
      ></IonAlert>
    </>

  );
};
export default WorkZoneDataCard;

